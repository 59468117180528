import { useStaticQuery, graphql } from 'gatsby';

const usePolicies = () => {
  const data = useStaticQuery(graphql`
    query {
      shared: sanitySharedPolicies {
        checkIn
        checkOut
        maxStay
        minStay
        smoking
      }
      cancelation: sanityPolicy(title: { regex: "/cancel/i" }) {
        title
        body
      }
      event: sanityPolicy(title: { regex: "/event/i" }) {
        title
        body
      }
      pet: sanityPolicy(title: { regex: "/pet/i" }) {
        title
        body
      }
    }
  `);

  return data;
};

export default usePolicies;
