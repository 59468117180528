import React from 'react';
import tw, { styled } from 'twin.macro';
import { SectionTitle } from '../utils/elements.stc';

const PolicyContainer = styled.div`
  ${tw`pr-0 text-lg mb-8`};
`;
const PolicyItem = styled.p``;

const Policy = props => {
  const { title, body } = props;
  return (
    <PolicyContainer>
      <SectionTitle>{title}</SectionTitle>
      <PolicyItem>{body}</PolicyItem>
    </PolicyContainer>
  );
};

export default Policy;
