import React from 'react';
import tw, { styled } from 'twin.macro';
import { FaBath, FaBed, FaDoorOpen, FaUsers } from 'react-icons/fa';

const SummaryWrapper = styled.div`
  ${tw`block pb-8 pt-4 justify-between text-gray-800`}
  ${props => props.display === 'flex' && tw`flex flex-wrap`};
`;
const SummaryItem = styled.div`
  ${tw`flex pb-2 pr-6 text-base uppercase`}
`;
const IconWrapper = styled.span`
  ${tw`relative pr-2`}
  top: 2px;
`;

const LodgingSummary = props => {
  const { bedrooms, beds, guests, bathrooms, display = 'block' } = props;
  return (
    <SummaryWrapper display={display}>
      <SummaryItem>
        <IconWrapper>
          <FaUsers />
        </IconWrapper>
        <span>{guests} max guests</span>
      </SummaryItem>
      <SummaryItem>
        <IconWrapper>
          <FaDoorOpen />
        </IconWrapper>
        <span>{bedrooms === '0' ? 'Studio' : `${bedrooms} bedrooms`}</span>
      </SummaryItem>
      <SummaryItem>
        <IconWrapper>
          <FaBed />
        </IconWrapper>
        <span>{beds} beds</span>
      </SummaryItem>
      <SummaryItem>
        <IconWrapper>
          <FaBath />
        </IconWrapper>
        <span>{bathrooms} bathrooms</span>
      </SummaryItem>
    </SummaryWrapper>
  );
};

export default LodgingSummary;
