import tw, { styled } from 'twin.macro';

export const Section = styled.div`
  ${tw`md:flex md:flex-no-wrap md:px-8`}
`;
export const ColFull = styled.div`
  ${tw`w-full p-4 md:px-8 md:my-12`}
`;
export const ColOneThird = styled.div`
  ${tw`w-full md:w-1/3 p-4 md:px-8 md:mb-12`}
`;
export const ColTwoThird = styled.div`
  ${tw`w-full md:w-2/3 p-4 md:px-8  md:mb-12`}
`;
export const ColHalf = styled.div`
  ${tw`w-full md:w-1/2 p-4 md:px-8  md:my-12`}
`;
