import { useStaticQuery, graphql } from 'gatsby';

const useLodgingRates = () => {
  const data = useStaticQuery(graphql`
    query {
      sanityLodgingRates {
        peakSeasonStart
        peakSeasonEndMonth
        discountDescription
        houseRates {
          offSeasonRate
          offSeasonRateDiscounted
          peakSeasonRate
          peakSeasonRateDiscounted
        }
        standardCabinRates {
          offSeasonRate
          offSeasonRateDiscounted
          peakSeasonRate
          peakSeasonRateDiscounted
        }
        studioCabinRates {
          offSeasonRate
          offSeasonRateDiscounted
          peakSeasonRate
          peakSeasonRateDiscounted
        }
      }
    }
  `);

  return data.sanityLodgingRates;
};

export default useLodgingRates;
