import React from 'react';
import tw, { styled } from 'twin.macro';

import useLodgingRates from '../hooks/use-lodging-rates';
import useAffiliations from '../hooks/use-affiliations';
import useCompanyInfo from '../hooks/use-company-info';

const RateWrapper = styled.div`
  ${tw`font-heading mb-12 p-4 text-gray-800 border-2 border-solid border-white bg-primary-100 rounded shadow-lg`}
`;
const RateTableWrapper = styled.div`
  ${tw`mb-8`}
`;
const SeasonDesc = styled.p`
  ${tw`uppercase py-2 text-center text-base`}
`;
const RateTitle = styled.h2`
  ${tw`uppercase font-heading font-medium text-gray-900 pb-2 text-center text-2xl`}
`;
const SeasonTitle = styled.h3`
  ${tw`uppercase font-heading text-center text-xl pb-2`}
`;
const RateTable = styled.div`
  ${tw`flex justify-between`}
`;
const Rate = styled.h3`
  ${tw`text-center text-2xl pb-2 font-medium`}
`;
const ShopLink = styled.a`
  ${tw`block text-center font-bold text-base block pt-2 pb-6 underline uppercase`}
`;
const Phone = styled.a`
  ${tw`block m-0 text-center text-xl`}
`;

const LodgingRates = props => {
  const { typeId } = props;
  const allRates = useLodgingRates();
  const affiliates = useAffiliations();
  const { phoneNum } = useCompanyInfo();
  const fpaData = affiliates.find(item => item.affiliateUrl.match(/panangler/));
  const { peakSeasonStart, peakSeasonEndMonth, discountDescription } = allRates;
  let rates;

  switch (typeId) {
    case 'cabinStandard':
      rates = allRates.standardCabinRates;
      break;
    case 'cabinStudio':
      rates = allRates.studioCabinRates;
      break;
    case 'house':
      rates = allRates.houseRates;
      break;
    default:
      rates = null;
      break;
  }

  return (
    <RateWrapper>
      <RateTitle>Rates</RateTitle>
      <SeasonDesc>
        Peak Season is {peakSeasonStart} thru {peakSeasonEndMonth}
      </SeasonDesc>
      <RateTableWrapper>
        <SeasonTitle>Peak Season</SeasonTitle>
        <Rate>{rates.peakSeasonRate}</Rate>
      </RateTableWrapper>
      <RateTableWrapper>
        <SeasonTitle>Off Season</SeasonTitle>
        <Rate>{rates.offSeasonRate}</Rate>
      </RateTableWrapper>
      <SeasonTitle>Call For Reservation</SeasonTitle>
      <Phone href={`tel:${phoneNum}`}>{phoneNum}</Phone>
    </RateWrapper>
  );
};
export default LodgingRates;
