import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';
import Lightbox from 'react-image-lightbox';

const Container = styled.header`
  margin-bottom: 2rem;
`;

const FeaturedImage = styled.div`
  background-size: cover;
  background: url(${props => props.image}) center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  height: ${props => props.height};
  cursor: pointer;
`;

const IconContainer = styled.div`
  height: 2.5rem;
  width: 2.5rem;
  fill: #ccc;
  background-color: rgba(0, 0, 0, 0.6);
  margin: 0 0.5rem;
`;

const RoomCallout = styled.section`
  ${tw`text-lg py-2 bg-primary-600`};
  color: rgba(235, 224, 213, 1);
  text-align: center;
`;

const StepBackwardIcon = () => (
  <svg role="img" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 5h3v10H4V5zm12 0v10l-9-5 9-5z" />
  </svg>
);

const StepForwardIcon = () => (
  <svg role="img" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 5h3v10h-3V5zM4 5l9 5-9 5V5z" />
  </svg>
);

const ImageLightbox = props => {
  const { images } = props;
  const { height = '400px' } = props;
  const { callout = '' } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const bgImage = images[0].src;

  const openLightbox = index => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <Container>
      <FeaturedImage
        image={bgImage}
        height={height}
        onClick={() => openLightbox(0)}
        onKeyDown={() => openLightbox(0)}
      >
        <IconContainer>
          <StepBackwardIcon />
        </IconContainer>
        <IconContainer>
          <StepForwardIcon />
        </IconContainer>
      </FeaturedImage>

      {callout && <RoomCallout>{callout}</RoomCallout>}

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex].src}
          imageCaption={images[photoIndex].caption}
          nextSrc={images[(photoIndex + 1) % images.length].src}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
          enableZoom={false}
        />
      )}
    </Container>
  );
};

export default ImageLightbox;
