import React from 'react';
import { graphql } from 'gatsby';
import tw, { styled } from 'twin.macro';
import BlockContent from '@sanity/block-content-to-react';

import usePolicies from '../hooks/use-policies';
import PageContainer from '../components/page-container';
import GraphQLErrorList from '../components/graphql-error-list';
import { Section, ColOneThird, ColTwoThird } from '../components/grid';
import Policy from '../components/policy';
import LodgingRates from '../components/lodging-rates';
import LodgingSummary from '../components/lodging-summary';
import ImageLightbox from '../components/image-lightbox';
import SEO from '../components/seo';
import {
  TitleBar,
  PageTitle,
  SectionTitle,
  FlexContainer,
} from '../utils/elements.stc';

const AmenitiesWrapper = styled.div`
  ${tw`my-12 text-gray-800 text-base border-2 border-white rounded p-2 bg-primary-100 shadow-lg uppercase`}
`;
const AmenityItems = styled.p`
  ${tw`leading-relaxed`}
`;
const PolicyTitle = tw(SectionTitle)`pb-0`;
const PolicyGroup = styled.div`
  ${tw``}
`;
const PolicyValue = styled.div`
  ${tw`text-center uppercase md:text-left`}
`;

const LodgingTemplate = props => {
  const { data, errors } = props;
  const {
    lodging: {
      _id: lodgingId,
      _rawBody,
      ammenities,
      seo: { title, description, keywords },
      quantities: { bathrooms, bedrooms, beds, guests },
      lightboxImages,
    },
  } = data;

  const {
    shared: { checkIn, checkOut, minStay, maxStay },
    cancelation: { title: cancelTitle, body: cancelBody },
    event: { title: eventTitle, body: eventBody },
    pet: { title: petTitle, body: petBody },
  } = usePolicies();

  const processedImages = lightboxImages.slides.map(img => ({
    src: img.asset.fluid.src,
    caption: img.caption,
    alt: img.alt,
  }));

  return (
    <PageContainer>
      {errors && <SEO title="GraphQL Error" />}
      {errors && <GraphQLErrorList errors={errors} />}
      {!errors && (
        <SEO title={title} description={description} keywords={keywords} />
      )}

      {!errors && (
        <>
          <TitleBar tw="mb-4 md:mb-12">
            <PageTitle tw="font-light md:font-light">{title}</PageTitle>
          </TitleBar>
          <Section>
            <ColTwoThird>
              <ImageLightbox
                images={processedImages}
                height="470px"
                callout="Click Image for Gallery"
              />
              <LodgingSummary
                display="flex"
                guests={guests}
                bedrooms={bedrooms}
                beds={beds}
                bathrooms={bathrooms}
              />
              <SectionTitle>Description</SectionTitle>
              <div tw="leading-7">
                <BlockContent blocks={_rawBody} />
              </div>
              <AmenitiesWrapper>
                <SectionTitle>Amenities</SectionTitle>
                <AmenityItems tw="leading-7">{ammenities}</AmenityItems>
              </AmenitiesWrapper>
              <FlexContainer tw="md:justify-start">
                <PolicyGroup tw="md:pr-32">
                  <div>
                    <PolicyTitle>Check In</PolicyTitle>
                    <PolicyValue>{checkIn}</PolicyValue>
                  </div>
                  <div tw="pt-8">
                    <PolicyTitle>Check Out</PolicyTitle>
                    <PolicyValue>{checkOut}</PolicyValue>
                  </div>
                </PolicyGroup>
                <PolicyGroup>
                  <div>
                    <PolicyTitle>Minimum Stay</PolicyTitle>
                    <PolicyValue>{minStay}</PolicyValue>
                  </div>
                  <div tw="pt-8">
                    <PolicyTitle>Maximum Stay</PolicyTitle>
                    <PolicyValue>{maxStay}</PolicyValue>
                  </div>
                </PolicyGroup>
              </FlexContainer>
            </ColTwoThird>
            <ColOneThird>
              <LodgingRates typeId={lodgingId} />
              <Policy title={cancelTitle} body={cancelBody} />
              <Policy title={petTitle} body={petBody} />
              <Policy title={eventTitle} body={eventBody} />
            </ColOneThird>
          </Section>
        </>
      )}
    </PageContainer>
  );
};

export default LodgingTemplate;

export const query = graphql`
  query LodgingTemplateQuery($id: String!) {
    lodging: sanityLodging(id: { eq: $id }) {
      _id
      _rawBody
      ammenities
      seo {
        keywords
        description
        title
        slug {
          current
        }
      }
      quantities {
        bathrooms
        bedrooms
        beds
        guests
      }
      lightboxImages {
        slides {
          caption
          alt
          asset {
            fluid(maxWidth: 900) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
